<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRICE_TYPES } from './store'
import { ROUTES as PRICE_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import PriceListCommonFilters from './PriceListCommonFilters'
import PriceListTable from './PriceListTable'

export default {
  name: 'PriceListList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': PriceListTable,
    'common-filters': PriceListCommonFilters
  },
  created () {},
  data () {
    return {
      PRICE_ROUTES,
      title: this.$t('PriceList'),
      rolePerm: ['price_pricelist_list'],
      actionEnablePermission: ['price_pricelist_enable'],
      actionDisablePermission: ['price_pricelist_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__currency__name__icontains', placeholder: this.$t('Currency'), type: 'text', col: 6 },
        { key: 'filter__date_begin__gte', placeholder: this.$t('Date begin greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_begin__lte', placeholder: this.$t('Date begin less than equal'), minDate: 'filter__date_q__gte', type: 'date', col: 6 },
        { key: 'filter__date_end__gte', placeholder: this.$t('Date end greater than equal'), type: 'date', col: 6 },
        { key: 'filter__date_end__lte', placeholder: this.$t('Date end less than equal'), minDate: 'filter__date_firm_for__gte', type: 'date', col: 6 }

      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PRICE_TYPES.GENERIC.price.pricelist.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === PRICE_ROUTES.PRICE_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('price_pricelist_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PRICE_TYPES.GENERIC.price.pricelist.LIST.MUTATIONS.setSellectedList,
      setSelectAll: PRICE_TYPES.GENERIC.price.pricelist.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PRICE_TYPES.GENERIC.price.pricelist.LIST.ACTIONS
    }),
    openEditForm (id) {
      this.$router.push({ name: PRICE_ROUTES.PRICE_LIST_CREATE })
    },
    openDetailForm (id) {
      this.$router.push({ name: PRICE_ROUTES.PRICE_LIST_DETAIL, params: { id: id } })
    }
  }
}
</script>
